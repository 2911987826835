// import React, { useEffect, useState } from 'react';
// import Amplify, { Auth, Hub } from 'aws-amplify';
// import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
// import awsconfig from '../aws-exports';

// Amplify.configure(awsconfig);

// export default function App() {
//   const [user, setUser] = useState(null);
//   const [customState, setCustomState] = useState(null);

//   useEffect(() => {
//     const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
//       switch (event) {
//         case "signIn":
//           setUser(data);
//           break;
//         case "signOut":
//           setUser(null);
//           break;
//         case "customOAuthState":
//           setCustomState(data);
//       }
//     });

//     Auth.currentAuthenticatedUser()
//       .then(currentUser => setUser(currentUser))
//       .catch(() => console.log("Not signed in"));

//     return unsubscribe;
//   }, []);

//     return (
//     <div className="App">
//       <button onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook })}>Open Facebook</button>
//       <button onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google })}>Open Google</button>
//       <button onClick={() => Auth.federatedSignIn()}>Open Hosted UI</button>
//       <button onClick={() => Auth.signOut()}>Sign Out {user.getUsername()}</button>
//     </div>
//   );
// }


import React, { useEffect, useState } from 'react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });

    getUser().then(userData => setUser(userData));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
  }

  return (
    <div>
      <p>User: {user ? JSON.stringify(user.attributes) : 'None'}</p>
      {user ? (
        <button onClick={() => Auth.signOut()}>Sign Out</button>
      ) : (
        <button onClick={() => Auth.federatedSignIn()}>Federated Sign In</button>
      )}
    </div>
  );
}

export default App;